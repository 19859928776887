@tailwind base;
@tailwind components;
@tailwind utilities;
@media (max-width: 768px) {
    /* Show the hamburger menu on smaller screens */
    .hamburger {
      display: block;
    }
  
    /* Hide the default navigation on smaller screens */
    .nav {
      display: none;
    }
  
    /* Ensure mobile navigation appears properly */
    .mobileNavVisible {
      display: flex;
    }
  }
  @media screen and (max-width: 768px) {
    .navLinks {
      display: none !important; /* Ensure desktop links are hidden */
    }
    .menuIcon {
      display: block !important; /* Show menu icon */
    }
  }
  
  .mobileMenu {
    transition: all 0.3s ease-in-out; /* Smooth toggle animation */
  }
  
  .login-message {
    margin: 20px auto;
    text-align: center;
    background-color: #2e2a39;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .login-message h2 {
    font-size: 20px;
    color: #ee66a6;
  }
  
  .login-message p {
    font-size: 14px;
    color: #ccc;
  }
  @media (max-width: 768px) {
    .navContainer {
      display: none; /* Hide navigation by default on small screens */
    }
    .hamburger {
      display: block; /* Show the hamburger menu on small screens */
    }
  }
  